<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Importer les médailles</h3>
        </div>
        <div class="modal-body">
          <p>
            Vous devez dans un premier temps, "Exporter tous les vins" avec le
            bouton qui se trouve dans "Action". Le fichier Excel devrait
            contenir 11 colonnes.
          </p>
          <p>
            Vous pouvez dés lors modifier le fichier sous Excel pour indiquer
            <b>avec des 1</b> dans les colonnes Or, Argent, Excellence, les vins
            médaillés. Vous pouvez également indiquer les commentaires.
          </p>
          <m-form-file
            :multiple="true"
            class="input-avatar"
            label="sélectionnez un fichier"
            ref="mformfileImages"
            @inputfile="inputImages"
          />
          <div v-if="alertTxt" class="alert alert-success">
            {{ alertTxt }}
            <ul>
              <li v-for="(err, index) in alertImportErrors" :key="index">
                {{ err }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="cancelWin" v-if="!importOk">
            Annuler
          </button>
          <button class="btn btn-primary" @click="importfile">
            {{ importOk ? "Fermer" : "Importer" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mconfirmdialog",
  props: {
    value: Boolean
  },
  data() {
    return {
      dialog: false,
      file: {},
      alertTxt: "",
      importOk: false,
      alertImportErrors: [],
      disabledBtn: false,
      progressValue: 0,
      valid: true,
      images: []
    };
  },
  mounted() {},
  watch: {
    value: function(val) {
      if (val) {
        this.alertTxt = "";
        this.importOk = false;
        this.dialog = val;
        // if (this.$refs.mformfileImages) this.$refs.mformfileImages.reset();
        this.images = [];
        this.progressValue = 0;
        this.disabledBtn = false;
      }
    }
  },
  components: {},
  methods: {
    /* async savefile(files) {
      if (files.length) this.file = files[0];
    }, */
    inputImages(images) {
      this.image = [];
      if (images.length) {
        for (let iimg = 0; iimg < images.length; iimg++) {
          const image = images[iimg];
          this.images.push(image);
        }
        // this.$refs.mformfileImages.reset();
      }
    },
    async importfile() {
      if (this.importOk) {
        this.cancelWin();
        return;
      }
      this.disabledBtn = true;
      this.progressValue = 0;
      let formData = new FormData();
      for (var i = 0; i < this.images.length; i++) {
        let file = this.images[i];
        formData.append("image[]", file);
      }
      let response = await this.$axios.post(
        this.$config.server_url +
          "/backoffice/1.0/wines/import/all/" +
          new Date().getFullYear(),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      // console.log("response", response);
      this.alertImportErrors = [];
      if (response.data.data.success) {
        this.alertTxt = `Import OK - Nb gagnants : ${response.data.data.nbGagnants} - Or : ${response.data.data.nbGagnantsOr} - Argent : ${response.data.data.nbGagnantsArgent} `;
        this.importOk = true;
        this.disabledBtn = false;
      } else {
        this.alertTxt = "Veuillez vérifier les fichiers suivants :";
        this.alertImportErrors = response.data.data.errors;
      }
      this.$refs.mformfileImages.reset();
      this.images = [];
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("canceled");
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-dialog {
  max-width: 60%;
}
</style>
